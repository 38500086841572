//コピーライト
var TodayData = new Date();
var thisYear = TodayData.getFullYear();
window.onload = function() {
	document.getElementById('js_copyYear').innerHTML = '©' + ' ' + thisYear + ' JFE SHOJI ELECTRONICS CORPORATION. All Rights Reserved..';
};


// ヘッダー、スクロールでサイズ縮小（.-topMenuを追加）
$(function(){
	 $(window).scroll(function(){
			var scr = $(window).scrollTop();
			if(scr >= 80){
				 $("#header").addClass('is-animation');
			}else{
				 $("#header").removeClass('is-animation');
			}
	 })
})

// ハンバーガーメニュー
$('input#menu-btn-check').change(function() {
	$("#side").toggleClass("btnOn");
});
$(function(){
		$("#js_gnavMenu-openBtn").on("click", function() {
				var scrollTop = $(window).scrollTop();
				$(this).next().fadeToggle();
				$(this).toggleClass("js_gnavMenu-open");
				$('#wrapper').css({'position':'fixed','top':-scrollTop});
		});
});
$(function(){
		var w = $(window).width();
		var scrollTop = $(window).scrollTop();
		if (w < 3000) {
			$("#js_gnavMenu-closeBtn").on("click", function() {
					$('#wrapper').css({'position':'','top':'0'});
					$('html,body').scrollTop(scrollTop);
					$('#js_gnavMenu-openBtn').next().fadeToggle();
					$('#js_gnavMenu-openBtn').toggleClass("js_gnavMenu-open");
			});
			$("#js_gnavMenu-closeSub").on("click", function() {
					$('#wrapper').css({'position':'','top':'0'});
					$('html,body').scrollTop(scrollTop);
					$('#js_gnavMenu-openBtn').next().fadeToggle();
					$('#js_gnavMenu-openBtn').toggleClass("js_gnavMenu-open");
			});
			$("#js_gnavMenuLinkList a").on("click", function() {
					$('#wrapper').css({'position':'','top':'0'});
					$('html,body').scrollTop(scrollTop);
					$('#js_gnavMenu-openBtn').next().fadeToggle();
					$('#js_gnavMenu-openBtn').toggleClass("js_gnavMenu-open");
			});
		}
});

// スライダー「利用者の声」

$(function(){
	$('.slider_top').slick({
		// スライダーの自動切り替え
		autoplay: true,
		// 要素を中央に寄せる
		centerMode: false,
		// スライダーの下のページャー
		dots: true,
		// フォーカス時にスライダーを停止
		pauseOnFocus: true,
		// マウスホバー時にスライダーを停止
		pauseOnHover: true,
		// 一度にスクロールする画像の枚数
		slidesToScroll: 1,
		// 一度に表示する画像の枚数
		slidesToShow: 1,
		// スライダーアイテムの横幅をcssで制御
		variableWidth: true
	});
});

// フッターの固定ボタン

$(document).ready(function(){

		// ページがスクロールされるまでは隠しておく
		$("#FixedFt").hide();

		// スクロールが始まったら
		$(window).on("scroll", function() {
				// 100pxスクロールしたら表示
				if ($(this).scrollTop() > 100) {
						$('#FixedFt').slideDown("fast");
				// ページの一番上では非表示
				} else {
						$('#FixedFt').slideUp("fast");
				}

				// ページの高さ
				var scrollHeight = $(document).height();

				//  表示画面の高さ ＋ ページに対するスクロール量
				var scrollPosition = $(window).height() + $(window).scrollTop();

				//  フッターの高さ
				var footHeight = $("#Footer").innerHeight();

				// フッターの位置（ページの高さ − 表示画面の高さ ＋ ページに対する現在のスクロール量）までスクロールしてきたとき
				if ( scrollHeight - scrollPosition  <= footHeight ) {

				// 現在の下から位置が、フッターの高さの位置にはいったら
				// ".gotop"のpositionをabsoluteに変更し、フッターの高さの位置にする

						$("#FixedFt").css({
								"position": "absolute",
								"bottom": footHeight,
						});
				} else {
				// それ以外の場合は元のcssスタイルを指定
						$("#FixedFt").css({
								"position": "fixed",
								"bottom": "-1px",
						});
				}
		});
});

// タブ
$(function() {
		$(".js_tab .tabTitle").click(function() {
				var num = $(".js_tab .tabTitle").index(this);
				$(".tabContent").removeClass('active');
				$(".tabContent").eq(num).addClass('active');
				$(".js_tab .tabTitle").removeClass('active');
				$(this).addClass('active')
		});
});

// スムーズスクロール
$(function(){
	 $('a[href^="#"]').click(function() {
			var speed = 300;
			var href= $(this).attr("href");
			var target = $(href == "#" || href == "" ? 'html' : href);
			var position = target.offset().top;
			$('html,body').animate({scrollTop:position}, speed, 'swing');
			return false;
	 });
});

// ホバーメニュー
$(function(){
	$('.pullDownPc').hover(function() {
		var w = $(window).width();
		if (w > 768) {
			$(this).toggleClass('active');
			$(this).children('.pullDownContentPc').stop().slideToggle();
		}
	});
});

// トグル
$('.js_toggleBtn').click(function () {
    $(this).children(".js_togglContents").fadeToggle(300);
    $('.js_toggleBtn').not($(this)).children("js_togglContents").fadeOut(300);
});

// タブ
$('.tab_box .tab_btn').click(function() {
	var index = $('.tab_box .tab_btn').index(this);
	$('.tab_box .tab_btn, .tab_box .tab_panel').removeClass('active');
	$(this).addClass('active');
	$('.tab_box .tab_panel').eq(index).addClass('active');
});
// 検索用
$(function(){
    $('[name="industry"]').change(function(){
        var aryCmp = [];
        $('[name="industry"]:checked').each(function(index, element){
            aryCmp.push($(element).val());
        });
        $('#selected-industry').html(aryCmp.join(','));
    });
});
$(function(){
    $('[name="section"]').change(function(){
        var aryCmp = [];
        $('[name="section"]:checked').each(function(index, element){
            aryCmp.push($(element).val());
        });
        $('#selected-section').html(aryCmp.join(','));
    });
});
$(function(){
    $('[name="task"]').change(function(){
        var aryCmp = [];
        $('[name="task"]:checked').each(function(index, element){
            aryCmp.push($(element).val());
        });
        $('#selected-task').html(aryCmp.join(','));
    });
});


jQuery(function(){
	$('#select_industry,#button1').click(function (e) {
		$("#select_industryBox").fadeToggle(300);
	});

	$('#select_section,#button2').click(function (e) {
	    $("#select_sectionBox").fadeToggle(300);
	});

	$('#select_task,#button3').click(function (e) {
	    $("#select_taskBox").fadeToggle(300);
	});

	$('.search_category .check').click(function(e){
		if($(this).prop('checked')) {
			$(this).parent().addClass('on');
		} else {
			$(this).parent().removeClass('on');
		}
	});
});

// 製品詳細のスライダー
$('.solution__mainSlide').slick({
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	asNavFor: '.solution__subSlide'
});
$('.solution__subSlide').slick({
	slidesToShow: 3,
	slidesToScroll: 1,
	asNavFor: '.solution__mainSlide',
	dots: false,
	arrows: true,
	centerMode: true,
	focusOnSelect: true
});


// サイドメニュー
$(function() {
	var side = $(".sidebar__inner nav");
	var cont = $("main");
	var sidebar = side.offset().top;
	var kizi = cont.outerHeight() - 1;
	$(window).on("scroll", function() {
	var scroll = $(window).scrollTop();
    if(scroll >= sidebar) {
      if(scroll >= kizi) {
        side.css({position: "fixed", top: "40px", left: 0, bottom: "auto", height: "100%"});
      } else {
        side.css({position: "fixed", top: "40px", left: 0, bottom: "auto", height: "100%"});
      }
    } else {
      side.css({position: "relative", top: "auto", right: "auto", bottom: "auto", height: "100%"});
    }
  });
});		

